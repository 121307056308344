import { ActionTree, GetterTree, MutationTree } from "vuex";
type State = {
  koalaToken: {
    accessToken: string;
    refreshToken: string;
  };
  productCatalogToken: {
    accessToken: string;
    accessTokenExptime: number;
    refreshToken: string;
    refreshTokenExptime: number;
  };
};

const state: State = {
  koalaToken: {
    accessToken: "",
    refreshToken: "",
  },
  productCatalogToken: {
    accessToken: "",
    accessTokenExptime: 0,
    refreshToken: "",
    refreshTokenExptime: 0,
  },
};

const getters: GetterTree<State, any> = {
  getKoalaTokenAccess: (state) => state.koalaToken.accessToken,
  getKoalaTokenRefresh: (state) => state.koalaToken.refreshToken,
  getProductCatalogTokenAccess: (state) =>
    state.productCatalogToken.accessToken,
  getProductCatalogTokenRefresh: (state) =>
    state.productCatalogToken.refreshToken,
};

const mutations: MutationTree<State> = {
  updateKoalaToken(state, token: string) {
    state.koalaToken.accessToken = token;
  },
  updateKoalaRefreshToken(state, token: string) {
    state.koalaToken.refreshToken = token;
  },
  updateProductCatalogToken(state, token: string) {
    state.productCatalogToken.accessToken = token;
  },
};

const actions: ActionTree<State, any> = {
  storeTokenProductCatalog(context, token: string) {
    context.commit("updateProductCatalogToken", token);
  },
  storeTokenKoala(context, koalaToken: string) {
    context.commit("updateKoalaToken", koalaToken);
  },
  storeRefreshTokenKoala(context, refreshToken: string) {
    context.commit("updateKoalaRefreshToken", refreshToken);
  }
};

const Auth = { state, getters, mutations, actions };

export default Auth;
