import store from "@/store";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { KoalaPlusChatCreditServiceClient } from "@/serviceClients/KoalaPlusChatCreditServiceClient";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/",
    name: "main",
    component: () =>
      import(/* webpackChunkName: "main-layout" */ "../views/MainLayout.vue"),
    children: [
      {
        path: "/transactions/invoice",
        name: "Invoice",
        component: () =>
          import(
            /* webpackChunkName: "home-view" */ "../views/InvoiceLayout.vue"
          ),
        meta: {
          headerTitle: "Invoice",
          requiresAuth: true,
          premium: false,
        },
      },

      {
        path: "/contacts/add-contact",
        name: "Add Contact",
        component: () =>
          import(
            /* webpackChunkName: "home-view" */ "../views/AddContactLayout.vue"
          ),
        meta: {
          headerTitle: "Tambah Kontak",
          requiresAuth: true,
          premium: false,
        },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(
  async (to: Route, _from: Route, next: NavigationGuardNext) => {
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //   if (!store.getters.getIsLoggedIn) {
    //     next({ name: "Login" });
    //   } else {
    //     const clientId = store.getters.getClientId;
    //     const koalaPlusChatCreditServiceClient =
    //       new KoalaPlusChatCreditServiceClient();
    //     koalaPlusChatCreditServiceClient
    //       .getBalanceByClientId({
    //         clientId: clientId,
    //       })
    //       .then((res) => {
    //         console.log("getBalance", res);
    //         store.dispatch("setBalance", res.chatCredit.balance);
    //       })
    //       .catch((err) => {
    //         console.log("err getBalance", err);
    //       });
    //     next();
    //   }
    // } else {
    next();
    // }
  }
);

export default router;
