import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Auth from "@/store/modules/Auth";
import Account from "@/store/modules/Account";
import GlobalConfiguration from "@/store/modules/GlobalConfiguration";
import StoreInfo from "@/store/modules/StoreSetting";
import MainOverlay from "@/store/modules/MainOverlay";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    Auth,
    Account,
    GlobalConfiguration,
    StoreInfo,
    MainOverlay,
  },
  plugins: [vuexLocal.plugin],
});
