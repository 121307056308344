import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  balanceCredit: number;
  accountStoreName: string;
  accountName: string;
  accountSubscriptionDateEnd: number;
  accountStatus: string;
  // accountRole: string;
  accountId: string;
  accountEmail: string;
  wabaId: string;
  wabaStatus: string;
  wabaPhone: string;
};

const state: State = {
  balanceCredit: 0,
  accountStoreName: "",
  accountName: "",
  accountSubscriptionDateEnd: 0,
  accountStatus: "",
  // accountRole: "",
  accountId: "",
  accountEmail: "",
  wabaId: "",
  wabaStatus: "",
  wabaPhone: "",
};

const getters: GetterTree<State, any> = {
  getAccountStatus: (state) => state.accountStatus,
  getAccountName: (state) => state.accountName,
  getAccountStoreName: (state) => state.accountStoreName,
  getAccountSubscriptionDateEnd: (state) => state.accountSubscriptionDateEnd,
  // getAccountRole: (state) => state.accountRole,
  getBalanceCredit: (state) => state.balanceCredit,
  getIsPremium: (state) =>
    state.accountStatus === "Premium" || state.accountStatus === "Free Trial",
  getAccountId: (state) => state.accountId,
  getAccountEmail: (state) => state.accountEmail,
  getWabaId: (state) => state.wabaId,
  getWabaStatus: (state) => state.wabaStatus,
  getWabaPhone: (state) => state.wabaPhone,
};

const mutations: MutationTree<State> = {
  updateAccountStatus(state, value: string) {
    state.accountStatus = value ?? "Free";
  },
  updateAccountName(state, value: string) {
    state.accountName = value;
  },
  updateAccountStoreName(state, value: string) {
    state.accountStoreName = value;
  },
  updateAccountSubscriptionDateEnd(state, value: number) {
    state.accountSubscriptionDateEnd = value;
  },
  // updateAccountRole(state, value: string) {
  //   state.accountRole = value;
  // },
  updateBalanceCredit(state, balanceCredit: number) {
    state.balanceCredit = balanceCredit;
  },
  updateAccountId(state, value: string) {
    state.accountId = value;
  },
  updateAccountEmail(state, value: string) {
    state.accountEmail = value;
  },
  updateWabaStatus(state, value: string) {
    state.wabaStatus = value;
  },
  updateWabaId(state, value: string) {
    state.wabaId = value;
  },
  updateWabaPhone(state, value: string) {
    state.wabaPhone = value;
  },
};

const actions: ActionTree<State, any> = {
  setAccountStatus(context, value: string) {
    context.commit("updateAccountStatus", value);
  },
  setAccountName(context, value: string) {
    context.commit("updateAccountName", value);
  },
  setAccountStoreName(context, value: string) {
    context.commit("updateAccountStoreName", value);
  },
  setAccountSubscriptionDateEnd(context, value: number) {
    context.commit("updateAccountSubscriptionDateEnd", value);
  },
  // setAccountRole(context, value: string) {
  //   context.commit("updateAccountRole", value);
  // },
  setBalance(context, balanceCredit: number) {
    context.commit("updateBalanceCredit", balanceCredit);
  },
  setAccountId(context, value: string) {
    context.commit("updateAccountId", value);
  },
  clearAccount(context) {
    context.commit("updateAccountStatus", "");
    context.commit("updateAccountName", "");
    context.commit("updateAccountStoreName", "");
    context.commit("updateAccountSubscriptionDateEnd", "");
    context.commit("updateAccountRole", "");
    context.commit("updateBalanceCredit", 0);
  },
  setAccountEmail(context, value: string) {
    context.commit("updateAccountEmail", value);
  },
  setWabaStatus(context, value: string) {
    context.commit("updateWabaStatus", value);
  },
  setWabaId(context, value: string) {
    context.commit("updateWabaId", value);
  },
  setWabaPhone(context, value: string) {
    context.commit("updateWabaPhone", value);
  },
};

const Account = { state, getters, mutations, actions };

export default Account;
