import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  mainOverlay: boolean;
};

const state: State = {
  mainOverlay: false,
};

const getters: GetterTree<State, any> = {
  getMainOverlay: (state) => state.mainOverlay,
};

const mutations: MutationTree<State> = {
  updateMainOverlay(state, isShow: boolean) {
    state.mainOverlay = isShow;
  },
};

const actions: ActionTree<State, any> = {
  setMainOverlay(context, isShow: boolean) {
    context.commit("updateMainOverlay", isShow);
  },
  clearMainOverlay(context) {
    context.commit("updateMainOverlay", false);
  },
};

const MainOverlay = { state, getters, mutations, actions };

export default MainOverlay;
