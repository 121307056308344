import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueI18n from "vue-i18n";

Vue.use(Vuetify);
Vue.use(VueI18n);

import id from "../locales/id";

Vue.component("BroadcastSendAddReceiverComponent", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "id";
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { id },
    current: "id",
  },
});
