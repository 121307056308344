import { ActionTree, GetterTree, MutationTree } from "vuex";

type SnackbarModel = {
  message: string;
  isActive: boolean;
  color: string;
};

type State = {
  priceInbound: number;
  priceOutbound: number;
  loading: boolean;
  snackbar: SnackbarModel;
};

const state: State = {
  priceInbound: 0,
  priceOutbound: 0,
  loading: false,
  snackbar: {
    message: "",
    isActive: false,
    color: "",
  },
};

const getters: GetterTree<State, any> = {
  getPriceInbound: (state) => state.priceInbound,
  getPriceOutbound: (state) => state.priceOutbound,
  getCommonLoading: (state) => state.loading,
  getSnackbarMessage: (state) => state.snackbar.message,
  getSnackbarColor: (state) => state.snackbar.color,
  getIsActiveSnackbar: (state) => state.snackbar.isActive,
};

const mutations: MutationTree<State> = {
  updatePriceInbound(state, priceInbound: number) {
    state.priceInbound = priceInbound;
  },
  updatePriceOutbound(state, priceOutbound: number) {
    state.priceOutbound = priceOutbound;
  },
  updateCommonLoading(state, loading: boolean) {
    state.loading = loading;
  },
  updateSnackbar(state, snackbar: SnackbarModel) {
    state.snackbar = snackbar;
  },
};

const actions: ActionTree<State, any> = {
  setPriceInbound(context, priceInbound: number) {
    context.commit("updatePriceInbound", priceInbound);
  },
  setPriceOutbound(context, priceOutbound: number) {
    context.commit("updatePriceOutbound", priceOutbound);
  },
  clearConfiguration(context) {
    context.commit("updatePriceInbound", 0);
    context.commit("updatePriceOutbound", 0);
  },
  setCommonLoading(context, loading: boolean) {
    context.commit("updateCommonLoading", loading);
  },
  setSnackbar(context, snackbar: SnackbarModel) {
    context.commit("updateSnackbar", snackbar);
  },
  clearSnackbar(context) {
    context.commit("updateSnackbar", {
      message: "",
      isActive: false,
      color: "",
    });
  },
};

const GlobalConfiguration = { state, getters, mutations, actions };

export default GlobalConfiguration;
