import { id } from "vuetify/src/locale";

export default {
  ...id,

  labelSelect: "Pilih",
  labelSaldo: "Saldo",
  labelDownload: "Download",
  labelEmail: "Email",
  labelPassword: "Password",
  labelOptional: "Optional",
  labelLater: "Nanti Saja",
  labelTopUpNow: "Top up sekarang",
  labelUnderstand: "Saya Mengerti",
  warnEmailRequired: "Email wajib diisi",
  warnEmailFormat: "Format email salah",
  warnPasswordRequired: "Password wajib diisi",
  noMutationDataText: "Tidak ada riwayat mutasi",
  labelTopUpAlertSucceed: "Hore! Kamu berhasil top up {amount} Chat Credits.",
  labelDeleteFilter: "Hapus filter",

  tabTitleBroadcastSend: "Kirim Broadcast",
  tabTitleBroadcastHistory: "Riwayat",
  tabTitleChatCreditsMutation: "Mutasi",

  tabTitleChatSettingOperationalHour: "Jam Operasional CS",
  tabTitleChatSettingFollowUp: "Follow Up",
  tabTitleChatSettingAutoClose: "Auto Close",

  settingPlanAndPricingTitleLabel: "Paket Langganan",
  settingPlanAndPricingDescriptionLabel:
    "Pilih paket KOALA+ sesuai kebutuhan tokomu",

  planAndPricingPlanTitle: "Harga terjangkau & buka akun tanpa biaya.",
  planAndPricingPlanDescription:
    "Dapatkan bonus sampai total 350.000 Chat Credits setiap bulan, dengan langganan KOALA+. <br>" +
    "Semua paket sudah termasuk seluruh fitur KOALA+ premium.",
  planAndPricingPlanButtonLabel: "Berlangganan",
  planAndPricingPlanButtonLabelExtend: "Perpanjang",
  planAndPricingPlanButtonCurrentLabel: "Paket saat ini",
  planAndPricingPlanNote:
    "*Promo berlaku bagi yang berlangganan sebelum 31 Desember 2022",
  planAndPricingWABATitle: "Hubungkan akun WABA",
  planAndPricingWABADescription:
    "Untuk mulai berlangganan KOALA+, kamu harus menghubungkan akun WhatsApp Business API (WABA) dengan KOALA+ dulu.",
  planAndPricingWABAButtonLabel: "Mulai sekarang",
  planAndPricingWABAFeeTitle: "Biaya WhatsApp Business API",
  planAndPricingWABAFeeDescription:
    "KOALA+ menggunakan WABA yang memiliki biaya terpisah dari biaya langganan. Kamu bisa top up Chat Credits untuk menggunakan fitur WABA.",
  planAndPricingWABAFeeButtonLabel: "Top Up Chat Credits",
  planAndPricingAlert:
    "Biaya langganan belum dibayar. Silakan lakukan pembayaran sebesar Rp {0} sekarang.",

  tabTitleUnassigned: "Belum Ditugaskan",
  tabTitleAssigned: "Ditugaskan",
  titleBlankChatroom: "Belum ada chat yang aktif",
  subtitleBlankChatroom: "Chat dari pelanggan akan muncul disini",
  noChat: "Tidak ada chat",
  latest: "Terbaru",
  oldest: "Terlama",
  apply: "Terapkan",
  selectedContact: "kontak dipilih",
  forward: "Teruskan",
  resolve: "Selesai",
  assign: "Tugaskan",
  titleForward: "Tugaskan ke CS",
  subtitleForward: "Pilih daftar CS",
  description: "Deskripsi",
  caption1Forward: "Kontak terpilih untuk ditutup chatnya.",
  caption2Forward: `Chat yang sudah dalam status ‘Selesai’ akan masuk ke menu Riwayat Chat`,

  chatHistoryTitle: "Riwayat Chat",
  chatHistorySubtitle:
    "Kamu bisa melihat riwayat chat yang sudah selesai atau expired",
  chatHistorySectionTitle: "Daftar Riwayat",
  chatHistoryPlaceholderFilterPhone: "Cari Kontak",
  chatHistoryPlaceholderFilterChannel: "Pilih Channel",
  chatHistoryPlaceholderFilterDate: "Tanggal Waktu Selesai",
  chatHistoryColumnContact: "Kontak Pelanggan",
  chatHistoryColumnChannel: "Channel",
  chatHistoryColumnCs: "Customer Service",
  chatHistoryColumnClosedAt: "Waktu Selesai",
  chatHistoryColumnRemark: "Keterangan",
  chatHistoryColumnAction: "Riwayat Chat",
  chatHistoryDialogTitle: "Riawayat Chat",
  chatHistoryOverlayText:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur ini untuk mendukung jualanmu",
  chatHistoryOverlayButton: "Langganan sekarang",
  chatHistoryEmptyTitle: "Belum ada Riwayat Chat",
  chatHistoryEmptySubtitle1:
    "Semua riwayat chat yang kamu balas ke pelanggan akan muncul di sini.",

  noMutationTitle: "Mutasi Kosong",
  noMutationDesc: "Tidak ada mutasi di periode terpilih",
  noMutationClose: "Mengerti",

  topUpValidateTitle: "Top up gagal",
  topUpValidateDesc:
    "Silakan melakukan pembayaran pada proses top up sebelumnya.",
  topUpValidateClose: "Mengerti",

  broadcastPriceAlert: "Biaya untuk broadcast adalah {0} chat credits/kontak",
  broadcastAddReceiverTitle: "Isi no. HP satu per satu",
  broadcastAddReceiverSubtitle:
    "Masukkan no. HP penerima secara manual, format 628xxx atau 08xxx.",
  broadcastAddReceiverPhoneHint: "No. handphone penerima",
  broadcastAddReceiverAddBtn: "Tambah",
  broadcastAddReceiverValidationInvalidNumber: "Nomor tidak valid",
  broadcastAddReceiverValidationInvalidLength: "Nomor minimal 8-13 angka",
  broadcastAddReceiverValidationInvalidAlreadyExit: "Nomor sudah terdaftar",

  broadcastBulkReceiverTitle: "Isi banyak no. HP sekaligus",
  broadcastBulkReceiverSubtitle:
    "Pastikan format file <b>.xls / .xslx</b> dan nama file <b>tidak diubah</b>.",
  broadcastBulkReceiverDownload: "Download Excel",
  broadcastBulkReceiverUpload: "Upload Excel",
  broadcastBulkReceiverValidationInvalidFormat: "Format file tidak sesuai",
  broadcastBulkReceiverValidationErrorUpload:
    "Upload gagal, silahkan cek kembali jaringan internet kamu",
  broadcastBulkReceiverValidationInvalidContent:
    "Terdapat {0} nomor yang tidak valid",

  labelFilterSortBroadcastHistory: "Urutkan",
  labelFilterTemplateBroadcastHistory: "Nama Template",
  labelDateTemplateBroadcastHistory: "Tanggal",
  columnNameTemplateBroadcastHistory: "Nama Template",
  columnNameSentBroadcastHistory: "Jumlah Dikirim",
  columnNameFailedBroadcastHistory: "Gagal",
  columnNameBroadcastTimeBroadcastHistory: "Waktu Broadcast",
  columnNameDescriptionBroadcastHistory: "Keterangan",
  columnNameDownloadCustomerDataBroadcastHistory: "Download Detail",

  columnNameChatCreditsTime: "Waktu",
  columnNameChatCreditsMutationType: "Deskripsi Transaksi",
  columnNameChatCreditsMutationAmount: "Mutasi",
  columnNameChatCreditsMutationBalance: "Saldo Chat Credit",

  columnNameChatSettingDayLabel: "Hari",
  columnNameChatSettingOperationalHourLabel: "Jam Operasional",
  columnNameChatSettingMessageOnOperationalHourLabel:
    "Chat ketika jam operasional",
  columnNameChatSettingMessageOutsideOperationalHourLabel:
    "Chat diluar jam operasional",
  columnNameChatSettingActionLabel: "Action",

  labelChatCreditsTopUpNominal: "Isi Jumlah Top Up",
  labelChatCreditsTopUpPaymentMethod: "Metode Pembayaran",
  labelChatCreditsTopUpSelectPaymentMethod: "Pilih metode pembayaran",
  labelChatCreditsTopUpContinuePayment: "Lanjut bayar",

  labelTitleChatSetting: "Chat berdasarkan jam operasional",
  labelSubtitleChatSetting: "Jam operasional dalam WIB",

  labelChatSettingAutoCloseTitle: "Chat auto close",
  labelChatSettingAutoCloseColumnTitle1: "Isi chat auto close",
  labelChatSettingAutoCloseColumnDescription1:
    "Chat autoclose adalah chat otomatis yang dikirim ke pelangganmu sudah tidak membalas chat dari CS",
  labelChatSettingAutoCloseColumnTitle2: "Auto close setelah berapa menit?",
  labelChatSettingAutoCloseSuccessMessage:
    "Berhasil melakukan update chat auto close",

  labelChatSettingFollowUpTitle: "Chat follow up",
  labelChatSettingFollowUpColumnTitle1: "Isi Chat follow up",
  labelChatSettingFollowUpColumnDescription1:
    "Chat follow up adalah chat otomatis yang dikirim ke pelangganmu yang belum membalas chat dari CS",
  labelChatSettingFollowUpColumnTitle2:
    "Kirim chat follow up setelah berapa menit?",
  labelChatSettingFollowUpSuccessMessage:
    "Berhasil melakukan update chat follow up",

  labelChatSettingCancelLabel: "Batalkan",
  labelChatSettingResetLabel: "Reset Sekarang",
  labelChatSettingDialogTitleLabel: "Reset chat otomatis",
  labelChatSettingDialogDescriptionLabel:
    "Chat otomatis dan jam operasional pada hari Senin akan direset",

  labelChatSettingOperationalHourEditDialogLabel: "Tambahkan",

  labelChatSettingOperationalHourEditDialogTitleLabel: "Ubah Auto Reply",
  labelChatSettingOperationalHourAddDialogTitleLabel: "Tambah Auto Reply",
  labelChatSettingOperationalHourEditDialogOperationalHourLabel:
    "Jam operasional",
  labelChatSettingOperationalHourEditDialogSelectHourLabel:
    "Pilih Jam (dalam WIB)",
  labelChatSettingOperationalHourEditDialog24HourLabel: "24 Jam",
  labelChatSettingOperationalHourEditDialogOpeningHourLabel: "Jam Buka",
  labelChatSettingOperationalHourEditDialogCloseHourLabel: "Jam Tutup",
  labelChatSettingOperationalHourEditDialogChatInHourLabel:
    "Chat ketika jam operasional",
  labelChatSettingOperationalHourEditDialogShowChatLabel:
    "Tampilkan chat ini 1x saja dalam sehari",
  labelChatSettingOperationalHourEditDialogChatOutHourLabel:
    "Chat diluar jam operasional",
  labelChatSettingOperationalHourEditDialogApplyInAllDayLabel:
    "Terapkan disemua hari?",

  appBarChatCredits: "Kredit Chat",
  appBarCredits: "Kredit",
  menuHome: "Beranda",
  menuProduct: "Produk",
  menuTransaction: "Transaksi",
  menuWhatapp: "Whatsapp",
  menuBroadcast: "Broadcast",
  menuTemplate: "Template",
  menuChatbot: "Chatbot",
  menuCustomerService: "Customer Service",
  menuChatHistory: "Riwayat Chat",
  menuChatSetting: "Pengaturan Chat",
  menuInbox: "Chat Masuk",
  menuChatCredits: "Chat Credits",
  menuAddProduct: "Tambah Produk",
  menuProductList: "Daftar Produk",
  menuCategory: "Kategori",
  menuSetting: "Pengaturan",
  menuSettingStore: "Pengaturan Toko",
  menuSettingAdmin: "Pengaturan Admin",
  menuPlanPricing: "Plan & Pricing",

  menuChatSettingTitle: "Pengaturan Chat Otomatis CS",
  menuChatSettingDesc:
    "Kamu bisa mengatur balasan chat CS secara otomatis ke pelangganmu",
  menuChatbotDesc:
    "Biaya auto reply chatbot adalah {0} chat credits/conversation dalam 24 jam.",
  menuChatSettingPriceDesc:
    "Biaya setiap balasan chat adalah {0} chat credits/chat dan hanya berlaku untuk chat dari whatsapp saja.",

  menuChatbotActivation: "Aktifkan chatbot",
  menuChatSettingAutoActivation: "Aktifkan chat otomatis",
  menuWallet: "Wallet",

  menuBroadcastTitle: "Pesan Broadcast",
  menuChatbotTitle: "Chatbot",
  menuChatCreditsTitle: "Chat Credits",
  menuChatCreditsDesc:
    "Miliki Chat Credits untuk biaya kirim pesan ke pelanggan. Top up dan lihat total pemakaian di halaman mutasi.",

  menuChatCreditsBonusAlertTitle: "Bonus Chat Credits sampai total {amount}!",
  menuChatCreditsBonusAlertDesc:
    "Dengan langganan KOALA+ premium, chat creditsmu akan di top up sampai total {amount} setiap bulannya.",
  menuChatCreditsBonusUpgradePremium: "Upgrade Premium",

  menuChatCreditsBalanceLabel: "Saldo",

  menuChatCreditsTopUpAlertDesc:
    "Top up belum dibayar. Silakan lakukan pembayaran sebesar {amount} sekarang.",
  menuChatCreditsTopUpAlertButton: "Bayar",

  menuChatCreditsFilterDateLabel: "Tanggal",
  menuChatCreditsFilterMutationTypeLabel: "Jenis Mutasi",

  broadcastReceiverTitle: "Daftar Penerima Broadcast",
  broadcastReceiverSubTitle: "Kuota maks. kirim ke 1000 kontak/hari.",
  broadcastReceiverSubTitleTooltips: "Kuota maks. kirim ke 1000 kontak/hari.",
  broadcastReceiverCounter: " penerima",
  broadcastReceiverCounterSelection: " telah dipilih",
  broadcastReceiverListReveal: "Selengkapnya",
  broadcastReceiverListConceal: "Tutup",
  broadcastReceiverSelectedAll: "pilih semua",
  broadcastReceiverSelectedCancel: "batalkan",
  broadcastReceiverDeleteAllConfirmTitle: "Hapus Daftar Penerima?",
  broadcastReceiverDeleteAllConfirmContent:
    "Kamu akan menghapus semua nomor penerima pada daftar",
  broadcastReceiverDeleteAllConfirmYes: "Ya, hapus",
  broadcastReceiverDeleteAllConfirmNo: "Tidak",
  broadcastReceiverDeletePartialConfirmTitle: "Hapus Daftar Penerima?",
  broadcastReceiverDeletePartialConfirmContent:
    "Kamu akan menghapus nomor penerima yang telah dipilih pada daftar",
  broadcastReceiverDeletePartialConfirmYes: "Ya, hapus",
  broadcastReceiverDeletePartialConfirmNo: "Tidak",

  broadcastDetailTitle: "Detail Broadcast",
  broadcastDetailTemplate: "Template yang digunakan",
  broadcastDetailTemplateSelection: "Pilih Template",
  broadcastDetailSend: "Kirim Broadcast",
  broadcastDetailStoreName: "Nama Toko",
  broadcastDetailStoreNameEmpty: "Isi detail tokomu",
  broadcastDetailStoreLink: "Link Toko",
  broadcastDetailDescription: "Keterangan",
  broadcastDetailDescriptionPlaceholder: "Isi Keterangan",
  broadcastDetailDescriptionSubtitle:
    "Contoh: pelanggan baru, pelanggan tetap, promo Ramadhan, dll",
  broadcastDetailUploadPicture: "Upload gambar",
  broadcastDetailUploadPictureSubtitle1: "Gambar maks 2MB dan 500x500 pixel.",
  broadcastDetailUploadPictureSubtitle2:
    "Kamu bisa mengganti gambar dengan klik ‘Replace’",
  broadcastDetailUploadPictureInvalid: "Gambar tidak sesuai dengan ketentuan",
  broadcastDetailPreview: "Preview broadcast",
  broadcastDetailPreviewSubtitle:
    "Menampilkan preview chat yang dikirim ke penerima",
  broadcastDetailProduct: "Pilih Produk",
  broadcastDetailProductPlaceholder: "Pilih produk dari katalog",
  broadcastDetailProductEmpty: "Tambahkan produk di katalog",
  broadcastDetailSentConfirmTitle: "Lanjut Kirim Broadcast",
  broadcastDetailSentConfirmSubtitle:
    "Kamu akan mengirim broadcast ke {0} kontak via whatsapp, dengan biaya sebesar {1} credits.",
  broadcastDetailSentConfirmYes: "Ya, kirim sekarang",
  broadcastDetailSentConfirmNo: "Tidak",
  broadcastSentMessageSuccess:
    "Berhasil mengirim pesan broadcast ke {0} kontak.",
  broadcastSentInsufficientTitle: "Chat Credits Tidak Cukup",
  broadcastSentInsufficientSubtitle:
    "Silakan melakukan top up untuk melanjutkan kirim pesan broadcast",
  broadcastSentExceedTitle: "Kuota Broadcast Sudah Habis",
  broadcastSentExceedSubTitle:
    "Kuota harian sudah habis. Silakan coba lagi besok.",

  templateHeaderName: "Nama Template",
  templateHeaderType: "Tipe Template",
  templateHeaderContent: "Isi Template",
  templateSubmitNew: "Ajukan saran template baru",

  freeAccountSubscribeAction: "Berlangganan sekarang",
  freeAccountSubscribe:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur ini untuk mendukung jualanmu",
  freeAccountSubscribeBroadcast:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur broadcast untuk mempromosikan tokomu",
  freeAccountSubscribeTemplate:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur template untuk mempromosikan tokomu",
  freeAccountSubscribeChatbot:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur chatbot untuk mempromosikan tokomu",
  freeAccountSubscribeChatCredits:
    "Dengan berlangganan sekarang, kamu bisa menggunakan fitur chat credits untuk mempromosikan tokomu",

  accountLogout: "Keluar",
  accountUpgrade: "Upgrade",
  accountUserType: "Tipe User",
  accountSubscriptionEnd: "Berlaku sampai ",

  chatCreditBalance: "Saldo",
  chatCreditUnit: " Chat Credits",
  chatCreditInboundMessage: "pesan inbound",
  chatCreditOutboundMessage: "pesan outbound",
  chatCreditMutationCheck: "Lihat Mutasi",
  chatCreditTopUp: "Top Up",

  menuWalletTitle: "Wallet",

  walletMutationTab: "Mutasi",
  walletPendingTab: "Pending",
  walletDetail:
    "Pantau mutasi dan saldo wallet tokomu. Pemasukan akan otomatis masuk ke rekening kamu maksimal 1x24 jam sejak transaksi selesai",
  walletMutationTitle: "Mutasi",
  walletPendingTitle: "Saldo Pending",
  walletBankAccountCreate: "Tambah Rekening",
  walletBankAccountDescription: "Cairkan dana otomatis ke rekeningmu",
  walletTableHeaderCreatedAt: "Waktu",
  walletTableHeaderDescription: "Deskripsi Transaksi",
  walletTableHeaderMutation: "Mutasi",
  walletTableHeaderSaldo: "Saldo Wallet",
  walletTableHeaderPendingAmount: "Jumlah",
  walletTableHeaderPendingStatus: "Status",
  walletTableHeaderPendingStatusInProgress: "Diproses",
  WalletTableHeaderPendingCreatedAt: "Estimasi Cair",
  WalletTableNoDataHeader: "Belum ada rekening bank terpasang",
  WalletTableNoDataDescription:
    "Pasang rekening bank untuk pencairan dana setelah transaksi dengan pembeli.",

  categoryListTitle: "Daftar Kategori",
  categoryAddButton: "Tambah Kategori",
  categorySearch: "Cari nama kategori",
  categoryDisplay: "Tampilkan",
  categoryName: "Nama Kategori",
  categoryTableAction: "Action",
  categoryEmptyTitle: "Belum ada data kategori",
  categoryEmptyDescription:
    "Yuk buat kategori produk untuk memudahkan pembeli melihat-lihat produkmu di website jualan",
  categoryAddDialogHeader: "Tambah Kategori",
  categoryDialogLaterButton: "Nanti saja",
  categoryDialogSaveButton: "Tambahkan",
  categoryDialogCancelButton: "Batalkan",
  categoryDialogDeleteButton: "Hapus sekarang",
  categoryDialogDeleteHeader: "Hapus Kategori",
  categoryDialogDeleteDescription:
    "Kamu akan menghapusnya dari Daftar Kategori.",
  categoryEditDialogHeader: "Edit Kategori",
  categoryDialogUpdateButton: "Simpan",
  categoryDialogDeleteAllHeader: "Hapus semua kategori?",
  productListHeader: "Daftar Produk",
  productListDescription: "Lihat semua produk atau tambahkan disini",
  productAddNewProduct: "Tambah Produk",
  productAllProducts: "Semua Produk",
  productAvailableStatus: "Dijual",
  productNotAvailableStatus: "Tidak Dijual",

  labelProductSearch: "Cari nama produk",
  labelProductFilterByCategory: "Filter Kategori",
  labelProductHeaderPhoto: "Foto Produk",
  labelProductHeaderName: "Nama Produk",
  labelProductHeaderCategory: "Kategori",
  labelProductHeaderPrice: "Harga",
  labelProductHeaderDescription: "Deskripsi",
  labelProductHeaderActiveStatus: "Aktif",
  labelProductHeaderAction: "Action",

  labelProductActivateAll: "Aktifkan semua",
  labelProductDeactivateAll: "Non aktifkan semua",
  labelProductDelete: "Hapus",
  labelProductNoDataHeader: "Belum ada produk",
  labelProductNoDataDescription:
    "Yuk tambah produk sekarang, biar website jualan kamu bisa langsung aktif.",
  labelProductDeleteConfirmationTitle: "Hapus produk ini?",
  labelProductDeleteConfirmationLabel:
    "Kamu akan menghapus {0} dari Daftar Produk.",
  labelProductDeleteCancelLabel: "Batalkan",
  labelProductDeleteConfirmLabel: "Hapus Sekarang",
  labelProductMultipleDeleteConfirmationTitle:
    "Kamu akan menghapus {0} produk.",

  labelCategoryMultipleDeleteConfirmationTitle:
    "Kamu akan menghapus {0} kategori.",

  labelCategoryMultipleDeleteAllConfirmationTitle:
    "Kamu akan menghapus semua yang ada di Daftar Kategori.",

  labelCategoryDeleteCategoryWithName: "Hapus Kategori {0}?",

  labelProductMultipleActivationConfirmationTitle: "Ubah status stok?",
  labelProductMultipleActivationLabel:
    "Kamu akan merubah status stok barang dari {0} produk.",
  labelProductMultipleActivationConfirmLabel: "Ya",
  labelErrorMessageDuplicateCategory:
    "Nama kategori sudah terdaftar sebelumnya",

  labelAddProductDetailHeaderTitle: "Detail Produk",
  labelAddProductDetailProductName: "Nama produk",
  labelAddProductDetailProductNameHint: "Isi nama produk",
  labelAddProductDetailProductCategory: "Kategori",
  labelAddProductDetailProductCategoryHint: "Pilih kategori",
  labelAddProductDetailProductAddCategoryText: "Tambah kategori baru",
  labelAddProductDetailProductPrice: "Harga produk",
  labelAddProductDetailProductPriceHint: "Isi harga produk",
  labelAddProductDetailProductType: "Tipe produk",
  labelAddProductDetailPhysicalProduct: "Fisik",
  labelAddProductDetailNonPhysicalProduct: "Non Fisik",
  labelAddProductDetailProductMeasurementUnit: "Satuan",
  labelAddProductDetailProductWeight: "Berat",
  labelAddProductDetailProductWeightHint: "Isi  berat",

  labelAddProductVariantHeaderTitle: "Varian ",
  labelAddProductOptional: "Opsional",
  labelAddProductVariantActivateButtonText: "Aktifkan Varian",
  labelAddProductVariantRemoveButtonText: "Hapus Grup Varian",
  labelAddProductVariantAddOptionText: "+ Tambah pilihan varian ({0}/10)",
  labelAddProductVariantMandatoryText: "Apa pelanggan wajib memilih varian?",
  labelAddProductVariantMultipleSelectionText:
    "Pelanggan bisa pilih beberapa varian?",
  labelAddProductVariantGroupAddOptionText: "Tambah grup varian ({0}/3)",

  labelAddProductDetailVariantGroupName: "Nama grup varian",
  labelAddProductDetailVariantGroupNameHint: "Isi nama grup varian",
  labelAddProductDetailVariantGroupDescription:
    "Contoh: Ukuran, Warna, Bahan, dll",
  labelAddProductDetailVariantOptions: "Pilihan varian",
  labelAddProductDetailVariantOptionsDescription1:
    "Harga tambahan adalah harga satuan produk ditambah dengan harga varian",
  labelAddProductDetailVariantOptionsDescription2:
    "Toggle on/off untuk mengatur ketersediaan produk",
  labelAddProductDetailVariantOptionNameHint: "Contoh: Hitam",
  labelAddProductDetailVariantOptionPriceHint: "jika gratis, isi 0",
  labelAddProductDetailVariantOptionalConfirm: "Ya",
  labelAddProductDetailVariantOptionalDeny: "Tidak",
  labelAddProductDetailVariantSingleSelect: "Hanya 1 pilihan",
  labelAddProductDetailVariantMultipleSelect: "Banyak pilihan",

  labelAddProductImageHeaderTitle: "Foto Produk",

  labelProductDescriptionHeaderTitle: "Deskripsi Produk",
  labelAddProductDescriptionHint: "Ketik deskripsi barang di sini",
  labelAddProductAddButton: "Tambah produk",
  labelAddProductSuccess: "Produk berhasil ditambahkan",

  settingAdmin: "Pengaturan Admin",
  settingAdminDetail: "Kamu bisa mengatur tugas dan peran anggota tim kamu",
  settingAdminList: "Daftar Pengguna",
  settingAdminAdd: "Tambah Pengguna",
  settingAdminInfo:
    "Maksimal tambah 5 pengguna. Untuk pengguna ke 6 dikenakan biaya Rp199.000/bulan. Untuk mengajukan penambahan silakan email ke business@koalaapp.id",
  settingAdminSearch: "Cari nama atau email",
  settingAdminFilter: "Filter Role",
  settingAdminName: "Nama",
  settingAdminEmail: "Email",
  settingAdminRole: "Role",
  settingAdminStatus: "Status",
  settingAdminAction: "Action",
  settingAdminPassword: "Password",
  settingAdminPasswordConfirm: "Konfirmasi Password",
  settingAdminSelectRole: "Pilih Role",
  settingAdminPlaceholderName: "Isi nama",
  settingAdminPlaceholderEmail: "Isi alamat email",
  settingAdminSave: "Tambahkan",
  settingAdminSaveEdit: "Simpan",
  settingAdminEdit: "Edit Pengguna",
  settingAdminDeleteTitle: "Hapus Akses Pengguna?",
  settingAdminDeleteDescription:
    "Pengguna yang sudah dihapus tidak bisa login lagi di KOALA Plus",
  settingAdminDeleteYes: "Ya, hapus",
  settingAdminDeleteNo: "Tidak",
  settingAdminAddSuccess: "Berhasil tambahkan pengguna baru",
  settingAdminDeleteSuccess: "Hapus pengguna berhasil",
  settingAdminAddMax:
    "Tidak bisa menambahkan user baru karena sudah mencapai batas maksimal",
  settingAdminUpdateSuccess: "Update pengguna berhasil",
  settingAdminPasswordError:
    "Password harus minimal 8 digit alfanumerik dan boleh karakter spesial",
  settingAdminRequiredForm: "Wajib diisi",
  settingAdminValidEmail: "Email tidak valid",
  settingAdminPasswordMatch: "Password tidak sama",
  settingAdminRoleAdminInfo: "Admin dapat mengakses semua fitur di KOALA Plus",
  settingAdminRoleCSInfo:
    "CS dapat mengakses fitur Transaksi, Produk dan Customer Service di KOALA Plus",
  settingAdminEmailUsed: "Email sudah terdaftar di KOALA Plus",

  settingStore: "Pengaturan Toko",
  settingStoreTabStoreInfo: "Informasi Toko",
  settingStoreTabAccountBank: "Rekening Bank",
  settingStoreTabCourier: "Pengiriman",
  settingStoreTabChannel: "Channel",
  settingStoreCourier: "Layanan Jasa Pengiriman",
  settingStoreSellerCourier: "Kurir Penjual",
  settingStoreContact: "Kontak Toko",
  settingStoreBankTitle: "Daftar Rekening Bank",
  settingStoreName: "Nama Toko",
  settingStoreNamePlaceHolder: "Isi nama toko",
  settingStoreAddress: "Alamat Toko",
  settingStoreAddressPlaceHolder: "Isi alamat toko",
  settingStoreCategory: "Bidang Usaha",
  settingStoreCategoryPlaceHolder: "Pilih Bidang Usaha",
  settingStoreBusinessType: "Tipe Bisnis",
  settingStoreBusinessTypePlaceHolder: "Pilih Tipe Bisnis",
  settingStoreSelectRule: "Pilih maksimal 3 bidang usaha",
  settingStoreCourierSellerName: "Nama kurir",
  settingStoreCourierSellerNamePlaceholder: "Isi nama kurir",
  settingStoreCourierSellerDeliveryFeeType: "Pilih jenis ongkos kirim",
  settingStoreCourierSellerDeliveryFee: "Biaya ongkos kirim",
  settingStoreCourierMaxPrice: "harga maksimal",
  settingStoreCourierSellerDeliveryFeeTotalProductRule:
    "Isi harga maksimal terlebih dahulu lalu isi biaya ongkirnya",
  settingStoreCourierSellerDeliveryFeeTotalProduct:
    "Biaya ongkos kirim diatas harga maksimal",
  settingStoreCourierSellerAddDeliveryFeeTotalProduct:
    "Tambah opsi biaya ongkir",
  settingStoreCourierSellerDeliveryFeePlaceholder:
    "Jika gratis, masukkan angka 0",
  settingStoreCourierSellerDeliveryEta: "Estimasi waktu pengiriman",
  settingStoreCourierSellerDeliveryEtaPlaceholder:
    "Isi estimasi waktu pengiriman",
  settingStoreCourierSellerWABA: "Anda belum menghubungkan akun WABA",
  settingStoreSaveEdit: "Simpan",
  settingStoreChannelDesc:
    "Hubungkan akun sosial mediamu dengan KOALA+ sehingga kamu bisa membalas pesan pelanggan di sini juga",
  settingStoreRemove: "Hapus",
  settingStoreConnect: "Hubungkan akun",

  loginBannerTitle: "Kelola chat & transaksi<br>di 1 dashboard",
  loginBannerSubTitle:
    "Partner bisnis resmi Facebook yang terintegrasi dengan<br>" +
    "WhatsApp Business API",
  loginFormTitle: "Masuk",
  loginFormInputEmail: "Email",
  loginFormInputEmailMessageRequired: "Email wajib diisi",
  loginFormInputEmailMessageError: "Format email salah",
  loginFormInputPassword: "Password",
  loginFormInputPasswordMessageRequired: "Password wajib diisi",
  loginFormInputPasswordMessageError: "Email atau password tidak sesuai",
  loginFormSubmit: "Lanjut",
  loginFormSubmitError: "Email atau password tidak sesuai",
  loginFormForgotPassword: "Lupa Password?",
  loginFormTermHtml:
    'Dengan masuk, kamu menyetujui <a style="color:#8D8D8D; text-decoration:underline;">syarat & ketentuan</a> KOALA+.',
  loginFormRegisterLabel: "Buat Akun Baru",
  loginFormRegisterAction: "Daftar",

  resetPhoneInputFormTitle: "Lupa Password?",
  resetPhoneInputFormSubtitle:
    "Masukkan no handphone yang terhubung dengan<br>KOALA+ dan kami akan kirim kode OTP.",
  resetPhoneInputFormPhoneLabel: "Nomor HP",
  resetPhoneInputFormPhoneRequired: "Nomor HP wajib diisi",
  resetPhoneInputFormPhoneError: "Format nomor HP tidak valid",
  resetPhoneInputFormPhoneUnregister: "Nomor HP tidak terdaftar di KOALA+",
  resetPhoneInputFormSubmit: "Lanjut",
  resetPhoneInputFormOTPTitle: "Masukkan kode OTP",
  resetPhoneInputFormOTPSubtitle:
    "Tulis 5 digit kode rahasia yang kami kirim via<br>WhatsApp ke nomor:",
  resetPhoneInputFormOTPTimerTitle: "Belum terima kode? ",
  resetPhoneInputFormOTPTimerCount: "Tunggu {0} detik",
  resetPhoneInputFormOTPTimerFinish: "Kirim Ulang",
  resetPhoneInputFormPasswordTitle: "Masukkan password baru",
  resetPhoneInputFormPasswordSubtitle:
    "Password harus 8 digit alfanumerik dan<br>boleh karakter spesial.",
  resetPhoneInputFormPasswordSubmit: "Ubah Password",
  resetPhoneInputFormPasswordNewLabel: "Password",
  resetPhoneInputFormPasswordConfirmLabel: "Konfirmasi Password",
  resetPhoneInputFormSuccessTitle: "Berhasil ubah password",
  resetPhoneInputFormSuccessSubtitle:
    "Sebentar lagi kamu akan diarahkan ke halaman login.",
  resetPhoneInputFormPasswordNewMessageRequired: "Password wajib diisi",
  resetPhoneInputFormPasswordMessageError:
    "Password harus 8 digit alfanumerik dan memiliki karakter spesial.",
  resetPhoneInputFormPasswordConfirmMessageRequired:
    "Konfirmasi Password wajib diisi",
  resetPhoneInputFormPasswordConfirmMessageError:
    "Konfirmasi Password harus sama",

  signUpStepperAccount: "Informasi Akun",
  signUpStepperStore: "Informasi Toko",
  signUpStepperWaba: "Hubungkan WABA",
  signUpStepperSubscription: "Langganan Premium",
  signUpAccountInputFormTittle: "Buat Akun Baru",
  signUpAccountInputFormPhoneLabel: "Nomor HP",
  signUpAccountInputFormPhoneRequired: "Nomor HP wajib diisi",
  signUpAccountInputFormEmailLabel: "Email",
  signUpAccountInputFormEmailRequired: "Email wajib diisi",
  signUpAccountInputFormEmailInvalid: "Email tidak valid",
  signUpAccountInputFormPasswordLabel: "Password",
  signUpAccountInputFormPasswordRequired: "Password wajib diisi",
  signUpAccountInputFormPasswordInfo:
    "Password harus minimal 8 digit alfanumerik dan boleh karakter spesial.",
  signUpAccountInputFormPasswordConfirmLabel: "Konfirmasi Password",
  signUpAccountInputFormPasswordConfirmRequired:
    "Konfirmasi Password wajib diisi",
  signUpAccountInputFormPasswordConfirmHasEqual:
    "Konfirmasi Password harus sama",
  signUpAccountInputFormTermHtml:
    'Dengan masuk, kamu menyetujui<a style="color:#8D8D8D; text-decoration:underline;"> syarat & ketentuan </a>KOALA+.',
  signUpAccountInputFormLoginLabel: "Sudah punya akun? ",
  signUpAccountInputFormLoginAction: "Login",
  signUpAccountInputFormSubmit: "Daftar",
  signUpStoreInputFormTittle: "Isi detail tokomu",
  signUpStoreInputFormNameLabel: "Nama Toko",
  signUpStoreInputFormAddressLabel: "Alamat Toko",
  signUpStoreInputFormBFieldLabel: "Bidang Usaha",
  signUpStoreInputFormBTypeLabel: "Tipe Bisnis",
  signUpStoreInputSubmit: "Lanjut",
  signUpStoreInputSkip: "Lewati",
  signUpConfirmNewTitle: "Daftar Nomor Baru",
  signUpConfirmNewContent:
    "Dengan daftar KOALA+, maka otomatis Anda telah terdaftar di aplikasi KOALA yang bisa Anda download di Playstore. Semua data akan saling terhubung.",
  signUpConfirmExistTitle: "Nomor HP sudah terdaftar",
  signUpConfirmExistContent:
    "Anda sudah memiliki akun di KOALA App.<br>" +
    "Semua data di akun KOALA App Anda saling terhubung dengan KOALA+.",
  signUpConfirmCancel: "Ubah",
  signUpConfirmSubmit: "Ya, lanjutkan",
  signUpWabaInputFormTitleHtml: "Hubungkan Akun<br>WhatsApp Business API",
  signUpSubscriptionTitle: "Pilih paket berlangganan KOALA+",
  signUpSubscriptionNotes:
    "*Tambah agen Customer Service Live dengan Rp199.000/agen/bulan",
  signUpSuccessTitle: "Berhasil buat akun!",
  signUpSuccessSubtitle:
    "Sebentar lagi kamu akan diarahkan ke halaman dashboard.",
  dashboardGreeting: "Hi, ",
  dashboardSetupAccount: "Yuk atur akun KOALA+ kamu!",
  dashboardSetupStep: "langkah selesai",
  dashboardUpgradeTitle: "Upgrade ke premium",
  dashboardUpgradeDescription:
    "Atur akun WhatsApp Business lalu langganan KOALA+ premium untuk menikmati fitur pendukung jualan yang lebih maksimal.",
  dashboardBonusFreeTitle: "Bonus chat credits hingga mencapai total 350.000!",
  dashboardBonusFreeDesc:
    "Langganan KOALA+ premium dan chat credits kamu akan ditop up hingga mencapai total 350rb setiap bulan.",
  dashboardBonusPremiumTitle: "Bonus Chat Credits sampai total 350.000!",
  dashboardBonusPremiumDesc:
    "Dengan langganan KOALA+ premium, chat creditsmu akan di top up sampai total 350.000 setiap bulannya.",
  dashboardBonusFreeTrialTitle:
    "Selamat! Kamu mendapat akses free trial KOALA+ premium selama 30 hari!",
  dashboardBonusFreeTrialDesc:
    "Kini kamu bisa menggunakan seluruh fitur KOALA+. Masa free trial akan berakhir tanggal",
  dashboardSubsAlert1: "Masa langgananmu berakhir di ",
  dashboardSubsAlert2:
    ". Perpanjang langgananmu yuk agar bisa terus pakai fitur KOALA+.",
  dashboardSubsRenew: "Perpanjang Langganan",
  dashboardTrxSumTitle: "Ringkasan Transaksi",
  dashboardProdListTitle: "Daftar Produk",
  dashboardTrxUnpaid: "Belum Bayar",
  dashboardTrxProcessed: "Diproses",
  dashboardTrxSent: "Dikirim",
  dashboardProdSale: "Produk Dijual",
  dashboardProdNotSale: "Tidak Dijual",
  dashboardFeatureCheck: "Cek fiturnya",
  dashboardFeatureTitle: "Fitur Chatting dengan Pelanggan",
  dashboardFeatureBroadcast: "Pesan Broadcast",
  dashboardFeatureBroadcastDetail: "Kirim pesan ke banyak kontak dengan cepat",
  dashboardFeatureChatbot: "Chatbot",
  dashboardFeatureChatbotDetail:
    "Aktifkan chatbot agar kamu bisa balas pesan dengan cepat",
  dashboardFeatureCS: "Customer Service",
  dashboardFeatureCSDetail:
    "Atur layanan pelangganmu sendiri untuk menjawab setap pesan yang masuk langsung di dashboard",
  dashboardViewMore: "Lihat Selengkapnya",
  dashboardHide: "Sembunyikan",
  dashboardSetWABA: "Atur WABA kamu",
  dashboardSetWABAAction: "Atur",
  dashboardSubs: "Subscribe KOALA+ premium",
  dashboardSubsAction: "Langganan",
  dashboardStartSellTitle: "Mulai Berjualan",
  dashboardStartSellDescription:
    "Isi informasi toko, tambah produk, dan verifikasi rekening bank untuk mulai jualan",
  dashboardFillStoreInfo: "Isi informasi toko",
  dashboardFillStoreInfoAction: "Isi sekarang",
  dashboardAddProduct: "Tambah produk",
  dashboardAddProductAction: "Tambah",
  dashboardVerifBankAccount: "Verifikasi rekening",
  dashboardVerifBankAccountAction: "Verifikasi",
  dashboardChatCustomerTitle: "Chat Pelangganmu",
  dashboardChatCustomerDescription:
    "Top up chat credits untuk gunakan WhatsApp Business. Kamu juga bisa gunakan fitur lain untuk berkomunikasi dan mempromosikan tokomu ke pelanggan.",
  dashboardTopUpChatCredit: "Top Up Chat Credits",
  dashboardTopUpChatCreditAction: "Top Up",
  dashboardSendBroadcast: "Kirim pesan broadcast",
  dashboardSendBroadcastAction: "Mulai kirim",
  dashboardSetCS: "Atur Chat Customer Service",
  dashboardSetCSAction: "Atur sekarang",
  dashboardActivateChatbot: "Aktifkan Chatbot",
  dashboardActivateChatbotAction: "Aktifkan",
  dashboardWABAReject0:
    "Permohonanmu ditolak Facebook. Silakan atur ulang akun WABA kamu. Mohon perhatikan beberapa hal berikut:",
  dashboardWABAReject1:
    "Pastikan display name kamu sesuai dengan Syarat dan Ketentuan Facebook.",
  dashboardWABAReject2:
    "Pastikan FB Business Manager diverifikasi sesuai dengan",
  dashboardWABARejectFBTerms: "Syarat dan Ketentuan Facebook",
  dashboardWABAReject3:
    "Jika kamu menghadapi kendala lain saat menghubungkan akun WABA, silakan hubungi kami via email ke",
  dashboardCheckDetail: "Lihat Detail",

  labelCustomerInformation: "Informasi Pelanggan",
  labelTitleContactName: "Nama",
  labelTitleContactPhone: "Nomor telepon*",
  labelTitleContactAddress: "Alamat penerima",
  labelTitleContactEmail: "Email",
  labelTitleContactOrderChannel: "Channel Pesanan",
  labelTitleContactChannel: "Channel",
  labelChooseContactChannel: "Pilih Channel",
  labelAddContactChannel: "Tambah Channel",
  labelPhoneNumberRequired: "Nomor telepon wajib diisi",
  labelTitleContactId: "Nomor WhatsApp / ID",
  labelTitleContactAddChannel: "Tambah Channel",
  labelTitleContactMandatoryPhone: "Nomor telepon wajib diisi",
  labelSaveBtnContact: "Simpan",
  labelContactNameHint: "Isi nama",
  labelContactPhoneHint: "Isi nomor telepon",
  labelContactAddressHint: "Isi alamat penerima",
  labelContactEmailHint: "Isi alamat email",

  labelPhoneNumberInvalid: "Nomor telepon tidak valid",
  labelPhoneNumberFirstDigitError: "Nomor telepon wajib diawali dengan angka 0 atau 62",

  labelAddressHeaderTitle: "Isi Alamat",
  labelAddressPostalCodeTitle: "Kode Pos",
  labelAddressRegionTitle: "Wilayah",
  labelAddressCompleteTitle: "Alamat Lengkap",
  labelAddressPostalCodeHint: "Isi kode pos",
  labelAddressChooseCoordinateTitle: "Pilih Koordinat (Opsional)",
  labelAddressSetLocationTitle: "Atur Lokasi",
  labelAddressDistrictTitle: "Kecamatan: ",
  labelAddressCityTitle: "Kota: ",
  labelAddressProvinceTitle: "Provinsi: ",
  labelAddressEdit: "Ubah",
  labelAddressDestionationAddressHeaderTitle: "Alamat Tujuan",
  labelAddressProvinceLabel: "Provinsi",
  labelAddressCityLabel: "Kota",
  labelAddressDistrictLabel: "Kecamatan",

  labelAddressChooseProvinceLabel: "Pilih Provinsi",
  labelAddressChooseCityLabel: "Pilih Kota",
  labelAddressChooseDistrictLabel: "Pilih Kecamatan",
};
